@import url(https://fonts.googleapis.com/css?family=VT323);

html {
  font-family: "VT323", monospace;
  /* background-image: url("https://media3.giphy.com/media/zW0n7AIWG4ka4/giphy.gif?cid=ecf05e47gc2ugtibegbcsu83ququ6r58s2e1jicxjxv9x7ca&rid=giphy.gif&ct=g"); */
  /* background-image: url("https://64.media.tumblr.com/e17fd7e558d081070b5dd4e19193d165/tumblr_pvl5j3H1r81qeyvpto1_500.gifv"); */
  background-position: center;
  background-size: cover;
  /* overflow: hidden; */
}

body {
  font-family: "VT323", monospace;
  background-image: url("https://64.media.tumblr.com/e17fd7e558d081070b5dd4e19193d165/tumblr_pvl5j3H1r81qeyvpto1_500.gifv");
  background-position: center;
  background-size: cover;
}

h1 {
  margin-bottom: 0;
}

.App {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}

.App-header {
  font-family: "VT323", monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: auto;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

button {
  font-family: "VT323", monospace;
  background: black;
  border-bottom: 6px inset rgba(0, 0, 0, 0.5);
  border-left: 6px inset rgba(0, 0, 0, 0.5);
  border-right: 6px inset rgba(255, 255, 255, 0.5);
  border-top: 6px inset rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
  margin: 1rem;
  min-width: 100px;
  padding: 0.5rem;
  text-transform: uppercase;
  width: auto;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: x-large;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid black;
  border-width: 2px;
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
}

.name {
  text-align: center;
  color: white;
  padding: 5px;
}
a {
  text-decoration: none;
  color: white;
  transition: color 1s;
}

.cover {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-width: 200px;
  margin-top: 20px;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin-top: 30px;
}

.icon {
  width: 30px;
  padding: 10px;
}

.nav-item {
  font-weight: bold;
  font-size: x-large;
  text-align: center;
}

iframe {
  width: 50%;
  margin: auto;
  border: none;
}

.music-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

@media screen and (min-width: 400px) {
  iframe {
    width: 80%;
  }
}

@media screen and (max-width: 400px) {
  body {
    zoom: 0.9;
  }
  iframe {
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 350px) {
  body {
    zoom: 0.8;
  }
  iframe {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  body {
    zoom: 0.7;
  }
  iframe {
    width: 100%;
  }
}
